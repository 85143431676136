import './cookies';
import './forms';
import './lazyload';
import './internal-links';
import './custom-dropdown';

objectFitImages();

// Add jQuery class to body
if ($('body').hasClass('no-jquery')) {
    $('body').removeClass('no-jquery');
}

$('body').addClass('jquery');

$('#btn-mobile-menu').click(function() {
    $(this).toggleClass('is-active');
    $('#main-nav').toggleClass('open');
    // $('body').toggleClass('of-h');
});

$('a[href*="#diensten"]').click(function(e) {
    if ($('#diensten-wrap').length > 0) {
        e.preventDefault();
        $('#diensten-wrap').addClass('open');
    } else {
        $('#btn-mobile-menu').removeClass('is-active');
        $('#main-nav').removeClass('open');

    }
});

$('#diensten-wrap a, #btn-diensten').click(function(e) {
    $('#diensten-wrap').removeClass('open');
});
