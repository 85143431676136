var cookiebar;

function removeCookieBar () {
    cookiebar.remove();
    $(document).off('click', '#frmcookies button');
}

$('body').on('click', '#frmcookies button', function (e) {
    $.ajax({
        type: 'POST',
        url: this.form.action,
        data: {
            COOKIES: $(this).val(),
        },
        dataType: 'text',

        success: function(data) {
            cookiebar = $('#cookiebar');
            var height = cookiebar.outerHeight();

            console.log(height);
            cookiebar.css({
                bottom: '-' + height + 'px'
            });

            setTimeout(function () {
                removeCookieBar();
            }, 1000);
        }
    });

    e.preventDefault(); // avoid to execute the actual submit of the form.
});
